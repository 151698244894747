.uppy-FileInput-container {
  margin-bottom: 15px;
}

.uppy-FileInput-btn {
  margin: 0;
  padding: 0;
  color: inherit;
  font-size: inherit;
  font-family: inherit;
  line-height: 1;
  background: none;
  border: 0;
  -webkit-appearance: none;
  padding: 10px 15px;
  color: #14457f;
  font-size: 0.85em;
  font-family: sans-serif;
  border: 1px solid #14457f;
  border-radius: 8px;
  cursor: pointer;
}
.uppy-FileInput-btn:hover {
  color: #fff;
  background-color: #14457f;
}